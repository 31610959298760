import { FC } from 'react';

import { IconListRendering } from '@sitecore/types/manual/IconList';
import { IconLoader, IconList as SparkyIconList } from '@sparky';
import { ChevronRightIcon } from '@sparky/icons';

const IconList: FC<IconListRendering> = ({ fields }) => {
  return (
    <SparkyIconList>
      {fields.items.map(item => {
        if (!item?.fields?.text?.value) return null;

        return (
          <SparkyIconList.Item
            key={item.id}
            icon={<IconLoader iconName={item?.fields?.icon?.name} fallbackIcon={<ChevronRightIcon />} />}>
            {item.fields.text.value}
          </SparkyIconList.Item>
        );
      })}
    </SparkyIconList>
  );
};

export default IconList;
